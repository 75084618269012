<template>
  <v-main>
    <v-container fluid>
      <div class="no-rights-msg" v-if="user_has_no_teams">
        It seems that you do not belong to any group that has the right to use
        PUCS.
        <br />
        If you think there is an error or if you wish to subscribe to this
        service, please contact us at contact<v-icon small>mdi-at</v-icon
        >strataggem.com.
      </div>
      
      <filters-dropdowns v-else />

      <router-view :key="$route.path" />
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getTeamCategory,
  isHomeUrl,
  isObjectViewUrl,
  isTeamsUrl
} from "../../utils/RoutesUtils";
import { getObjectsList } from "../../utils/ServerUtils";
import DropdownList from "./widgets/DropdownList.vue";
import FiltersDropdowns from "./widgets/FiltersDropdowns.vue";
export default {
  components: { DropdownList, FiltersDropdowns },
  name: "DefaultView",

  data() {
    return {
      user_has_no_teams: false,
      selected_team_id: null
    };
  },

  async created() {
    // get user's teams
    const objects_list = await getObjectsList(getTeamCategory());
    this.user_has_no_teams = null == objects_list || objects_list.length == 0;
  },

  mounted() {
    this.selected_team_id = this.filter_selected_team_id;
  },

  computed: {
    ...mapGetters(["filter_selected_team_id"]),

    team_dropdown_should_be_shown() {
      const current_url = this.$route.path;
      return (
        !isTeamsUrl(current_url) &&
        !isHomeUrl(current_url) &&
        !isObjectViewUrl(current_url)
      );
    },

    getTeamCategory() {
      return getTeamCategory();
    }
  },

  methods: {
    ...mapActions(["updateSelectedTeamId"]),
    ...mapActions(["updateSelectedTypeId"]),
    ...mapActions(["updateSelectedGroupId"]),

    teamDropdownSelected(object) {
      this.selected_team_id = null != object ? object.id : null;
      this.updateSelectedTeamId(this.selected_team_id);
      this.updateSelectedTypeId(null);
      this.updateSelectedGroupId(null);
    }
  }
};
</script>

<style scoped>
.no-rights-msg {
  background-color: white;
  margin: 0 1.5em;
  padding: 1em;
  border: solid 2px red;
  font-size: 1.2em;
  text-align: center;
}
</style>
